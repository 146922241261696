import React from "react"
import Heading from "../components/layout/heading.js"
import SEO from "../components/seo"
import { minHeightForMenuPage } from "../defines";
import { Segment } from "semantic-ui-react"

const IndexPage = ({ mobile }) => (
  <>
    <SEO title="Home" />
    <Segment
      textAlign="center"
      style={{ minHeight: minHeightForMenuPage(mobile), padding: "1em 0em" }}
      vertical
      inverted
    >
      <Heading mobile={mobile} />
    </Segment>
  </>
)

export default IndexPage;

import React from "react"
import headerLogo from "../../images/HeaderLogo.png"
import {
    Container,
    Header,
    Segment,
    Button
} from "semantic-ui-react"
import { navigate } from "gatsby"

const Heading = ({ mobile }) => {
    const getImg = () => mobile ? (<></>) : (<img
        src={headerLogo} alt="Vegandata logo"
        style={{
            marginBottom: 0,
            marginTop: mobile ? ".75em" : "1.5em"
        }}
    />);

    return (
    <Container text textAlign="center">
        { getImg() }
        <Header
            as="h2"
            content="Data/visualizations for all things vegan"
            inverted
            style={{
                fontSize: mobile ? "1.5em" : "1.7em",
                fontWeight: "normal",
                marginTop: mobile ? "0.35em" : "1.3em",
            }}
        />
        <Header
            as="h3"
            content="Suggestions/corrections? Please send a message to our social media!"
            inverted
            style={{
                fontSize: mobile ? "1em" : "1.2em",
                fontWeight: "normal",
                marginTop: mobile ? "0.5em" : "1.0em",
            }}
        />
            <Segment inverted vertical style={{ textAlign: "center" }}>
                <Button
                    circular
                    compact={mobile}
                    color="twitter"
                    icon="twitter"
                    onClick={() => navigate("https://twitter.com/vegandata")}
                />
                <Button
                    circular
                    compact={mobile}
                    color="instagram"
                    icon="instagram"
                    onClick={() => navigate("https://instagram.com/vegan_data")}
                />
            </Segment>
    </Container>);
}

export default Heading;
